import styled from '@emotion/styled'

const StyledSvg = styled.svg`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #56d81e;
    stroke-miterlimit: 10;
    animation: scale .3s ease-in-out .9s both;
    position:relative;
    top: 5px;
    right: 5px;
    margin: 0 auto;

    @keyframes scale {
        0%, 100% {
            transform: none;
        }

        50% {
            transform: scale3d(1.1, 1.1, 1);
        }
    }
`

const StyledCircle = styled.circle`
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #56d81e;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;

    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }
`

const StyledCheck = styled.path`
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;

    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }
`

export const Success = () => {
    return (
        <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <StyledCircle cx="26" cy="26" r="25" fill="none" />
            <StyledCheck fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </StyledSvg>
    )
}